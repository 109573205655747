import React from 'react';
import { LiveStreamingFilter } from 'forms';
import {
  AccessControl,
  Button,
  DataTable,
  hasAccess,
  LiveStreamDelete,
  LiveStreamingBlocked,
  Modal,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from 'components';
import avatcoin from 'assets/svg/avatcoin.svg';
import avatcoinsilver from 'assets/images/silvercoin.png';
import { connect } from 'react-redux';
import { fetchLiveStreaming } from 'actions';
import { dateFormatting } from 'utils/dateMethods';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import { DomesManagementService, LiveStreamingManagement } from 'services';
import { useHistory } from 'react-router-dom';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  fetchLiveStreamings: any;
  livestreaming: any;
}

const LiveStreaming = (props: Props) => {
  const {
    livestreaming,
    fetchLiveStreamings,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    startDate: '',
    endDate: '',
    domes: '',
    liveType: '',
    search: '',
    userType: '',
    page: 0,
    rowsPerPage: 10,
    isSortByTitle: '',
    isSortByCreated: '',
    isSortByScheduledDateTime: '',
    isSortByCategory: '',
    isAcceptedUserCount: '',
  });
  const [domes, setDomes] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    fetchLiveStreamings(filter);
  }, [fetchLiveStreamings, filter]);
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  React.useEffect(() => {
    LiveStreamingManagement.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
  }, []);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <div className={commonStyles.actionBtnsWrapper}>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.DELETE}
      >
        <div>
          <img
            src={deleteIcon}
            role="presentation"
            alt="delete"
            width="35"
            title="Delete"
            height="35"
            onClick={() => handleBlockUnblock('delete', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          {action === 'pending' ? null : (action !== 'blocked' ? (
            <img
              src={blockIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Block"
              height="35"
              onClick={() => handleBlockUnblock('blocked', idS)}
            />
          ) : (
            <img
              src={unblock}
              role="presentation"
              alt="unblock"
              width="35"
              title="Unblock"
              height="35"
              onClick={() => handleBlockUnblock('unblocked', idS)}
            />
          ))}
        </div>
      </AccessControl>
    </div>
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = livestreaming.data ? livestreaming.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const hideActions = () => hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.DELETE);

  const handleSubmitFilter = (data: any) => {
    if (data.domes !== ''
      || data.endDate !== null
      || data.liveType !== ''
      || data.search !== ''
      || data.startDate !== null
      || data.status !== '') {
      setFilter({
        ...filter,
        startDate: data.startDate,
        endDate: data.endDate,
        domes: data.domes,
        liveType: data.liveType,
        search: data.search.label,
        userType: data.status,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const headCells: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'eventtitle', label: 'Event Title', sort: true,
    },
    {
      id: 'description', label: 'Description', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'livetype', label: 'Live Type', sort: false,
    },
    {
      id: 'dateofcreation', label: 'Date of Creation', sort: true,
    },
    {
      id: 'livedateandtime', label: 'Live Date & Time', sort: true,
    },
    {
      id: 'numberofparticipants', label: 'Number of Participants', sort: true,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'totalticketcount', label: 'Total Ticket Count',
    },
    {
      id: 'paymenttype', label: 'Payment Type',
    },
    {
      id: 'price', label: 'Price',
    },
    {
      id: 'discountprice', label: 'Discounted Price',
    },
    {
      id: 'status', label: 'Status',
    },
    {
      id: 'duration', label: 'Duration',
    },
    {
      id: 'action', label: 'Action',
    }] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'eventtitle', label: 'Event Title', sort: true,
    },
    {
      id: 'description', label: 'Description', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'livetype', label: 'Live Type', sort: false,
    },
    {
      id: 'dateofcreation', label: 'Date of Creation', sort: true,
    },
    {
      id: 'livedateandtime', label: 'Live Date & Time', sort: true,
    },
    {
      id: 'numberofparticipants', label: 'Number of Participants', sort: true,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'totalticketcount', label: 'Total Ticket Count',
    },
    {
      id: 'paymenttype', label: 'Payment Type',
    },
    {
      id: 'price', label: 'Price',
    },
    {
      id: 'discountprice', label: 'Discounted Price',
    },
    {
      id: 'status', label: 'Status',
    },
    {
      id: 'duration', label: 'Duration',
    },
  ];
  const handleEventString = (evt: string) => {
    if (evt === 'private_event_paid') {
      return 'Private Event Paid';
    }
    if (evt === 'private_event_free') {
      return 'Private Event Free';
    }
    if (evt === 'public_event_free') {
      return 'Public Event Free';
    }
    if (evt === 'public_event_paid') {
      return 'Public Event Paid';
    }
    return '';
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    const Price = (
      <div className={commonStyles.c_price}>
        <img src={avatcoin} alt="avatcoin" />
        <span>{data.goldPrice}</span>
      </div>
    );
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      eventtitle: data.title !== undefined ? (data.title.length > 20 ? `${data.title.substring(0, 20)}...` : data.title) : '',
      description: data.description !== undefined ? (data.description.length > 20 ? `${data.description.substring(0, 20)}...` : data.description) : '',
      username: data.user.username,
      avatar: <img src={data.imageUrl} alt="" />,
      livetype: handleEventString(data.eventType),
      dateofcreation: dateFormatting(data.createdAt),
      livedateandtime: dateFormatting(data.startTime),
      numberofparticipants: data.acceptedUserCount,
      domes: data.category ? data.category.name : '',
      totalticketcount: data.participationLimit,
      paymenttype: data.isPaidEvent ? 'Paid' : 'Free',
      price: Price,
      discountprice: data.discountedPrice
        ? (
          <div className={commonStyles.c_price}>
            <img src={avatcoin} alt="avatcoin" />
            <span>{data.discountedPrice.gold}</span>
            <span className={commonStyles.separator}>+</span>
            <img src={avatcoinsilver} alt="avatcoin" />
            <span>{data.discountedPrice.silver}</span>
          </div>
        ) : '',
      status: (data.status === 'blocked' ? 'Cancelled'
        : (data.status === 'live' ? 'Ongoing' : (data.status === 'completed'
          ? 'Ended' : data.status[0].toUpperCase() + data.status.substring(1, data.status.length)))),
      duration: data.duration,
      ...(hideActions() && {
        action: data.status !== 'live' && data.status !== 'canceled' && actionButtons(data.status, idS),
      }),
    };
  }

  const rows: any = [];
  if (livestreaming.data !== null && livestreaming.data) {
    livestreaming.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else if (event.target.localName !== 'img') {
      history.push(`/livestreaming/livestreaminfo/${name.id}`);
    }
  };

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'eventtitle') {
      setFilter({
        ...filter,
        page: 0,
        isSortByTitle: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'dateofcreation') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'livedateandtime') {
      setFilter({
        ...filter,
        page: 0,
        isSortByScheduledDateTime: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategory: order === 'asc' ? '1' : '-1',
      });
    } // numberofparticipants
    if (orderBy === 'numberofparticipants') {
      setFilter({
        ...filter,
        page: 0,
        isAcceptedUserCount: order === 'asc' ? '1' : '-1',
      });
    }
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToExcel(selectedRowDataModified, 'LiveStreamManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToCSV(selectedRowDataModified, 'LiveStreamManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: '',
      search: '',
      isSortByTitle: '',
      isSortByCreated: '',
      isSortByScheduledDateTime: '',
      isSortByCategory: '',
      isAcceptedUserCount: '',
    });
  };
  return (
    <>
      {showModal && (
        <Modal
          id="block-user"
          isOpen={showModal}
          onClose={handleModalClose}
          onManageDisableScrolling={() => null}
          lightCloseButton={false}
        >
          {deleted.show && (
            <LiveStreamDelete
              handleModalClose={handleModalClose}
              id={deleted.id}
              fetchLiveStreaming={() => setFilter({ ...filter, page: 0 })}
            />
          )}
          {blocked.show && (
            <LiveStreamingBlocked
              handleModalClose={handleModalClose}
              id={blocked.id}
              fetchLiveStreaming={fetchLiveStreamings}
              status="blocked"
            />
          )}
          {unblocked.show && (
            <>
              <p>Event cancelled</p>
              <Button onClick={handleModalClose}>Ok</Button>
            </>
          )}
        </Modal>
      )}
      <LiveStreamingFilter
        handleFormSubmit={handleSubmitFilter}
        handleExport={handleExport}
        handleResetSorts={handleResetSorts}
        domes={domes}
        loaderExport={loaderExport}
      />
      <div className={commonStyles.dataTableCover}>
        <DataTable
          headCells_={headCells}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={livestreaming.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { livestreaming } = state;
  return {
    livestreaming,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchLiveStreamings: (payload: any) => dispatch(fetchLiveStreaming(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreaming);
