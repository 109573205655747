import api from '../api';

export class EventManagementService {
  static async getEventManagement(payload: any) {
    const {
      page,
      rowsPerPage,
      id,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&eventId=${id}${isSortByUsername !== '' && isSortByUsername !== 0 ? `&isSortByUsername=${isSortByUsername}` : ''}`;
    return api.getApiCall(`/api/v1/admins/events/reported-events?${apiEndPoint}`);
  }

  static async getEventUserReportService(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}&userReportedId=${id}`;
    return api.getApiCall(`api/v1/admins/reported-users/events?${apiEndPoint}`);
  }
}
