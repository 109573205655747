import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Chip } from '@material-ui/core';
import avatcoin from 'assets/svg/avatcoin.svg';
import silvercoin from 'assets/images/silvercoin.png';
import {
  Button,
  CircularProgressLoader,
  Modal,
  EventDetails,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  LiveStreamingBlocked,
  LiveStreamDelete,
  Accordion,
  NotificationDetailsLiveStream,
  ParticipantDetails,
} from '..';
import { livestreaminfo } from '../../actions';
import profileImg from '../../assets/svg/profilepic.svg';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting, getDateAndTime } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  livestreamInfo: any;
  id: any;
  fetchLiveStreamInfo: any;
  intl: any;
}

const LiveStreamInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    livestreamInfo,
    id,
    fetchLiveStreamInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchLiveStreamInfo(id);
  }, [fetchLiveStreamInfo, id]);
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [showUnBlock, setShowUnBlock] = React.useState(false);
  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
  };

  const handleEventString = (evt: string) => {
    if (evt === 'private_event_paid') {
      return 'Private Event Paid';
    }
    if (evt === 'private_event_free') {
      return 'Private Event Paid';
    }
    if (evt === 'public_event_free') {
      return 'Private Event Paid';
    }
    if (evt === 'public_event_paid') {
      return 'Private Event Paid';
    }
    return '';
  };

  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={commonStyles.modal_container}>
          {
            showBlock && (
              <LiveStreamingBlocked
                handleModalClose={handleModalClose}
                id={id}
                fetchLiveStreaming={() => fetchLiveStreamInfo(id)}
                status="blocked"
              />
            )
          }
          {showDelete && (
            <LiveStreamDelete
              handleModalClose={handleModalClose}
              id={id}
              fetchLiveStreaming={() => history.push('/livestreaming')}
            />
          )}
          {
            showUnBlock && (
              <>
                <p>Event cancelled</p>
                <Button onClick={handleModalClose}>Ok</Button>
              </>
            )
          }
        </div>
      </Modal>
      {
        livestreamInfo.data === null || livestreamInfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div />
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    {livestreamInfo.data.status !== 'live' && livestreamInfo.data.status !== 'cancelled'
                      && (
                        <>
                          <AccessControl
                            permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                            accessType={PERMISSION_TYPES.DELETE}
                          >
                            <span className={commonStyles.iconWrapper}>
                              <img
                                src={deleteImg}
                                alt="deleteImg"
                                role="presentation"
                                title="Delete"
                                onClick={() => {
                                  setShowModal(true);
                                  setShowDelete(true);
                                }}
                              />
                            </span>
                          </AccessControl>

                          <AccessControl
                            permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                            accessType={PERMISSION_TYPES.EDIT}
                          >
                            {livestreamInfo.data.status !== 'blocked' ? (
                              <span className={commonStyles.iconWrapper}>
                                <img
                                  src={blockImg}
                                  alt="blockImg"
                                  title="Block"
                                  role="presentation"
                                  onClick={() => {
                                    setShowModal(true);
                                    setShowBlock(true);
                                  }}
                                />
                              </span>
                            ) : (
                              <span className={commonStyles.iconWrapper}>
                                <img
                                  src={unblockImg}
                                  alt="unblockImg"
                                  title="unblock"
                                  role="presentation"
                                  onClick={() => {
                                    setShowModal(true);
                                    setShowUnBlock(true);
                                  }}
                                />
                              </span>
                            )}
                          </AccessControl>
                        </>
                      )}
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={commonStyles.profileImgWrapper}>
                    {livestreamInfo.data.imageUrl !== ''
                      && livestreamInfo.data.imageUrl !== undefined
                      ? (
                        <img
                          className={commonStyles.profileImg}
                          src={livestreamInfo.data.imageUrl}
                          alt="profileImg"
                        />
                      )
                      : <img src={profileImg} alt="profile" />}
                  </div>
                  <div className={commonStyles.userInfoinner}>
                    <div>
                      <h2>{livestreamInfo.data.title}</h2>
                      <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {livestreamInfo.data.description}
                      </p>
                    </div>
                    <div className={commonStyles.right_container}>
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        <span>
                          {dateFormatting(livestreamInfo.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Username
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.user?.username} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Likes
                      </p>
                      <div className="badge">
                        <Chip label={250} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Reactions
                      </p>
                      <div className="badge">
                        <Chip label={250} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Reports Count
                      </p>
                      <div className="badge">
                        <Chip label={250} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Dome
                      </p>
                      <div className="badge">
                        <Chip label="Health/Yoga" />
                      </div>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Live type
                      </p>
                      <div className="badge">
                        <Chip label={handleEventString(livestreamInfo.data.eventType)} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Live date & time
                      </p>
                      <div className="badge">
                        <Chip label={getDateAndTime(livestreamInfo.data.startTime)} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Live Status
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.status} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Live duration
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.duration} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Number of participants
                      </p>
                      <div className="badge">
                        <Chip label={livestreamInfo.data.acceptedUserCount} />
                      </div>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.pricingplan' })}
                      </p>
                      <div className="badge">
                        <Chip label={
                          livestreamInfo.data.isPaidEvent
                            ? (
                              <div>
                                Paid
                                {' '}
                                <img src={avatcoin} alt="avatcoin" />
                                {' '}
                                {livestreamInfo.data.goldPrice}
                              </div>
                            )
                            : 'UnPaid'
                        }
                        />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Discounted Pricing Plan
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div className={commonStyles.c_price}>
                            <img src={avatcoin} alt="avatcoin" />
                            <span>300</span>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Virtual gifts received
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div style={{ display: 'flex' }}>
                            Paid &nbsp;
                            <div>
                              <div className={commonStyles.c_price}>
                                <img src={avatcoin} alt="avatcoin" />
                                <span>{livestreamInfo.data.discountedPrice.gold}</span>
                                <span className={commonStyles.separator}>+</span>
                                <img src={silvercoin} alt="avatcoin" />
                                <span>{livestreamInfo.data.discountedPrice.silver}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Avatcoin Earned
                      </p>
                      <div className="badge">
                        <Chip label={(
                          <div className={commonStyles.c_price}>
                            <img src={avatcoin} alt="avatcoin" />
                            <span>300</span>
                          </div>
                        )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Participants Detail">
                <ParticipantDetails id={id} />
              </Accordion>
              <Accordion heading="Event Report Detail">
                <EventDetails id={id} />
              </Accordion>
              <Accordion heading="Notification Details">
                <NotificationDetailsLiveStream id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  livestreamInfo: state.livestreaminfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchLiveStreamInfo: (payload: any) => dispatch(livestreaminfo(payload)),
});

const LiveStreamInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(LiveStreamInfoComponent);

export default LiveStreamInfo;
